<script setup lang="ts">
import type { RuntimeConfig } from '@nuxt/schema'
import type { LoginFormData } from '~~/types'
// import type { trpcRouterOutput } from '~~/server/types'

const emit = defineEmits<{
  (e: 'update:modelValue', value: LoginFormData): void
}>()

// type TestCredentials = trpcRouterOutput['sys_Utils']['getTestCredentials']
type TestCredentials = RuntimeConfig['login']['testCredentials']

const logins = ref<TestCredentials>([])
const selectedItem = ref()

watch(
  selectedItem,
  (v) => {
    if (logins.value.length) {
      const sel = logins.value.find((e) => e.email === v)
      if (sel) emit('update:modelValue', { email: sel.email, password: sel.password })
    }
  },
  { immediate: true },
)

const { $trpc } = useNuxtApp()
const { data } = await $trpc.sys_Utils.getTestCredentials.useQuery()
if (data.value) logins.value = data.value as TestCredentials

onMounted(() => {
  selectedItem.value = 'soundcasterx@gmail.com'
  // selectedItem.value = 'admin@admin.com'
})
</script>

<template>
  <VSelect
    v-if="logins.length"
    v-model="selectedItem"
    :items="logins"
    item-title="text"
    item-value="email"
    label="SELECT USER"
  />
</template>
