<script setup lang="ts">
import { VForm } from 'vuetify/components'
import { localeLink } from '#imports'
import type { LoginFormData } from '~~/types'

definePageMeta({
  layout: 'blank',
  title: 'pages.login.title',
  redirectIfAuthenticated: true,
})

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const isPasswordVisible = ref(false)
const { emailValidator, requiredValidator } = useValidators()

const errors = reactive<{
  email: string | undefined
  password: string | undefined
}>({
  email: undefined,
  password: undefined,
})

const formRef = ref<VForm>()
const form = reactive<Partial<LoginFormData>>({})

const registerLink = computed(() => {
  if (route.query.to)
    return localeLink(`/register?to=${encodeURIComponent(String(route.query.to))}`)
  else return localeLink('/register')
})

const { initRecaptcha, executeRecaptcha } = useRecaptcha()

onMounted(() => {
  initRecaptcha()
})

async function login() {
  try {
    if (!form.email || !form.password) throw new Error('missing form data')
    form.recaptcha = await executeRecaptcha('login') // get recaptcha token
    const login = await userStore.login(form as LoginFormData)
    if (login) {
      let redirectUrl = localeLink({ name: 'private' })
      if (route.query.to) {
        // Redirect to `to` query if exist or redirect to index route
        redirectUrl = localeLink(decodeURIComponent(String(route.query.to)))
      }
      await navigateTo(redirectUrl)
    }
  } catch (error: unknown) {
    if (isTRPCClientError(error)) {
      if (error.data?.zodError) {
        // zod errors
      } else {
        const msg = error.message
        switch (msg) {
          case 'USER_DOES_NOT_EXIST':
          case 'NOT_VERIFIED_USER':
          case 'DELETED_USER':
          case 'ARCHIVED_USER':
          case 'DISABLED_USER':
            errors.email = t(msg)
            break
          case 'BLOCKED_USER':
            errors.email = `${t(msg)}, ${t('pages.login.retry later')}`
            break
          case 'WRONG_PASSWORD':
            errors.password = t(msg)
            break
          default:
            throw error
        }
      }
    }
  }
}

async function onSubmit() {
  errors.email = ''
  errors.password = ''
  const v = await formRef.value?.validate()
  if (v?.valid) await login()
}

// TODO remove logins selector
function changeLogin(e: LoginFormData) {
  if (e) {
    form.email = e.email
    form.password = e.password
  }
}
</script>

<template>
  <VRow no-gutters>
    <!-- Image/Video Background -->
    <LayoutsPublicLoginBgVideo>
      <LogoAuth />
    </LayoutsPublicLoginBgVideo>

    <VCol cols="12" lg="4" class="dvn-blank-panel">
      <LogoAuth class="d-lg-none mt-4" />
      <VCard flat :max-width="500" class="mt-12 mt-sm-0 pa-4">
        <VCardText>
          <h5 class="text-h5 font-weight-semibold mb-1 align-center d-flex">
            {{ $t('pages.login.Welcome') }}
            <LayoutsLogo width="30" class="ml-2" /><LayoutsLogoCut height="28" />
          </h5>
          <p class="mb-0">{{ $t('pages.login.Please sign-in') }}</p>
        </VCardText>

        <VCardText>
          <!-- auth providers -->
          <VCol cols="12" class="d-flex justify-center">
            <AuthenticationAuthProvider :title="$t('Login with')" />
          </VCol>
          <VCol cols="12" class="d-flex align-center">
            <VDivider />
            <span class="mx-4">{{ $t('or') }}</span>
            <VDivider />
          </VCol>
        </VCardText>

        <VCardText>
          <UsersLoginSelector
            class="mb-2"
            @update:modelValue="(e: LoginFormData) => changeLogin(e)"
          />
        </VCardText>
        <VCardText>
          <VForm ref="formRef" validate-on="submit" @submit.prevent="onSubmit">
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <VTextField
                  v-model="form.email"
                  :label="$t('Email')"
                  type="email"
                  maxlength="50"
                  placeholder="jimmy.page@gmail.com"
                  autocomplete="email"
                  autofocus
                  :rules="[requiredValidator, emailValidator]"
                  :error-messages="errors.email"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <VTextField
                  v-model="form.password"
                  :label="$t('Password')"
                  maxlength="50"
                  autocomplete="current-password"
                  :rules="[requiredValidator]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="errors.password"
                  :append-inner-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  @click:append-inner="() => (isPasswordVisible = !isPasswordVisible)"
                />

                <div class="d-flex align-center flex-wrap justify-space-between mt-1 mb-4">
                  <NuxtLink class="text-primary ms-2 mb-1" :to="localeLink('forgot-password')">
                    {{ $t('pages.forgot-password.Forgot Password') }} ?
                  </NuxtLink>
                </div>

                <VBtn block size="large" type="submit"> {{ $t('pages.login.Login') }} </VBtn>
              </VCol>

              <!-- create account -->
              <VCol cols="12" class="text-center">
                <span>{{ $t('pages.login.New on our platform') }} ?</span>
                <NuxtLink class="text-primary ms-2" :to="registerLink">
                  {{ $t('pages.login.Create an account') }}
                </NuxtLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use '@styles/pages/page-auth.scss';
</style>
